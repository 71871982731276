<template>
  <div id="writings" class="container">
    <h1 class="d-none d-xl-block">
      Some <span>Writings</span>
    </h1>
    <h2 class="d-xl-none d-sm-block">
      Some <span>Writings</span>
    </h2>
    <hr class="mt-5">
    <div class="row my-5">
      <div
        v-for="writing in getWritings"
        :key="writing.id"
        class="col-12 pb-4">
        <router-link
          :to="{name:'Writing', params:{writing: writing.id}}"
          class="writing-link"
        >
          {{writing.title}} »
        </router-link>
        <p class="metadata">
          {{writing.author}} @ {{writing.createdAt}}
        </p>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
/* eslint-disable */
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'EgirlWritings',
  methods: {
    ...mapActions(['queryWritings', 'clearWriting', 'createTimestamp'])
  },
  computed: {
    ...mapGetters(['getWritings'])
  },
  mounted () {
    this.clearWriting()
    this.createTimestamp('writings')
    this.queryWritings()
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';

#writings {
  margin-top: 83px;

  h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    text-transform: uppercase;
    padding-left: 19px;
    font-weight: normal;
    border-left: 5px solid $regular-pink;

    span {
      color: $regular-pink;
    }
  }

  h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 19px;
    border-left: 5px solid $regular-pink;

    span {
      color: $regular-pink;
    }
  }

  hr {
    background-color: $regular-pink;
  }

  a {
    color: white;

    &:hover {
      background-color: $light-pink;
      text-decoration: none;
    }

    &:visited {
      color: white;
    }
  }

  .writing-link {
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
  }

  .metadata {
    font-family: 'Anonymous Pro', monospace;
  }
}
</style>
